import { template as template_5ca458e6cba54cc4addbef7c0a40d765 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_5ca458e6cba54cc4addbef7c0a40d765(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
