import { template as template_10bf5d2d1d6c4f958e1fffb2568eacd9 } from "@ember/template-compiler";
const FKLabel = template_10bf5d2d1d6c4f958e1fffb2568eacd9(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
