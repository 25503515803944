import { template as template_c4d90ba25325413aabcb6f35dc599dfa } from "@ember/template-compiler";
const WelcomeHeader = template_c4d90ba25325413aabcb6f35dc599dfa(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
