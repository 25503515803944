import { template as template_474e817446224b1f8dabbf1083ddd312 } from "@ember/template-compiler";
const FKControlMenuContainer = template_474e817446224b1f8dabbf1083ddd312(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
