import { template as template_633196d5975a475b927d1bd19d470a70 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_633196d5975a475b927d1bd19d470a70(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
